<script>
import BaseProfileManager from '../BaseProfileManager'
import { Relation as RelationModel } from '@/models/Connect/Relation'

export default {
  name: 'RelationCreate',

  extends: BaseProfileManager,

  computed: {
    sidebarTitle () {
      return this.$t('pages.relations.create.sidebar_title')
    },

    sidebarDescription () {
      return this.$t('pages.relations.create.sidebar_description')
    }
  },

  mounted () {
    const profile = new RelationModel()

    this.form.merge(profile)
  },

  methods: {
    onSuccessHandler (response) {
      this.$notify.success({
        title: this.$t('general.success'),
        duration: 5000,
        message: this.$t('success.new_relation_added')
      })

      this.form.reset()
      this.$v.form.$reset()

      if (this.$route.query.redirect) {
        return this.$router.push({ path: this.$route.query.redirect })
      }

      if (!this.stayOnPageAfterSave) {
        this.$router.push({ name: 'connect.relation.view', params: { relationId: response.data.id } })
      }
    },

    onErrorHandler (error) {
      this.$displayRequestError(error, this.$t('errors.cannot_add_relation'))
    }
  }
}
</script>
