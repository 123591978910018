import _get from 'lodash/get'

/**
 * @alias HF_Relation
 * @description Relation returned from the API
 */
export class Relation {
  constructor (props) {
    /** @type {String} id */
    this.id = _get(props, 'id')

    /** @type {String} preferred_name */
    this.preferred_name = _get(props, 'preferred_name')

    /** @type {String} preferred_email */
    this.preferred_email = _get(props, 'preferred_email')

    /** @type {String} preferred_phone */
    this.preferred_phone = _get(props, 'preferred_phone')

    /** @type {String} picture_url */
    this.picture_url = _get(props, 'picture_url')

    /** @type {Array} names */
    this.names = _get(props, 'names', [])

    /** @type {Array} emails */
    this.emails = _get(props, 'emails', [])

    /** @type {Array} phones */
    this.phones = _get(props, 'phones', [])

    /** @type {Array} urls */
    this.urls = _get(props, 'urls', [])

    /** @type {Array} associations */
    this.associations = _get(props, 'associations', [])

    /** @type {Array} locations */
    this.locations = _get(props, 'locations', [])

    /** @type {Array} categories */
    this.categories = _get(props, 'categories', [])

    /** @type {Array} tags */
    this.tags = _get(props, 'tags', [])

    /** @type {Array} groups */
    this.groups = _get(props, 'groups', [])

    /** @type {Array} stats */
    this.stats = _get(props, 'stats', [])

    // TODO: Use the "locations" object
    this.location_country = _get(props, 'locations.0.country_code')
    this.location_region = _get(props, 'locations.0.region')

    // TODO: Use the "associations" object
    this.employment_association = _get(props, 'associations.0.association')
    this.employment_role = _get(props, 'associations.0.role')
    this.employment_country = _get(props, 'associations.0.country_code')
    this.employment_website = _get(props, 'associations.0.website')

    // Apply some sensible defaults...
    // TODO: Try to figure out a better way to have this
    // or improve the Models approach to have mutators..
    this.mapped_names = this.names.map((value) => {
      return { 'name': value }
    })
    this.mapped_emails = this.emails.map((value) => {
      return { 'email': value }
    })
    this.mapped_phones = this.phones.map((value) => {
      return { 'phone': value }
    })
    this.mapped_urls_website = _get(this.urls, 'website', []).map((value) => {
      return { 'url': value }
    })
    this.mapped_urls_facebook = _get(this.urls, 'facebook', []).map((value) => {
      return { 'url': value }
    })
    this.mapped_urls_instagram = _get(this.urls, 'instagram', []).map((value) => {
      return { 'url': value }
    })
    this.mapped_urls_linkedin = _get(this.urls, 'linkedin', []).map((value) => {
      return { 'url': value }
    })
    this.mapped_urls_pinterest = _get(this.urls, 'pinterest', []).map((value) => {
      return { 'url': value }
    })
    this.mapped_urls_twitter = _get(this.urls, 'twitter', []).map((value) => {
      return { 'url': value }
    })
    this.mapped_urls_youtube = _get(this.urls, 'youtube', []).map((value) => {
      return { 'url': value }
    })

    !this.mapped_names || this.mapped_names.length === 0 ? this.mapped_names = [{ name: '' }] : null
    !this.mapped_emails || this.mapped_emails.length === 0 ? this.mapped_emails = [{ email: '' }] : null
    !this.mapped_phones || this.mapped_phones.length === 0 ? this.mapped_phones = [{ phone: '' }] : null
    !this.mapped_urls_website || this.mapped_urls_website.length === 0 ? this.mapped_urls_website = [{ url: '' }] : null
    !this.mapped_urls_facebook || this.mapped_urls_facebook.length === 0 ? this.mapped_urls_facebook = [{ url: '' }] : null
    !this.mapped_urls_instagram || this.mapped_urls_instagram.length === 0 ? this.mapped_urls_instagram = [{ url: '' }] : null
    !this.mapped_urls_linkedin || this.mapped_urls_linkedin.length === 0 ? this.mapped_urls_linkedin = [{ url: '' }] : null
    !this.mapped_urls_pinterest || this.mapped_urls_pinterest.length === 0 ? this.mapped_urls_pinterest = [{ url: '' }] : null
    !this.mapped_urls_twitter || this.mapped_urls_twitter.length === 0 ? this.mapped_urls_twitter = [{ url: '' }] : null
    !this.mapped_urls_youtube || this.mapped_urls_youtube.length === 0 ? this.mapped_urls_youtube = [{ url: '' }] : null
  }
}
